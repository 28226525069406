import React from "react";
import EmployeeApprovalCard from "./EmployeeApprovalCard";
import { useDroppable } from "@dnd-kit/core";
import { useSelector } from "react-redux";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function ReviewerColumn({ reviewer, selectedMonth, selectedYear }) {
  const adminState = useSelector((state) => state.admin);

  const { setNodeRef } = useDroppable({
    id: reviewer.reviewer,
  });

  const findFullName = (employeeId) => {
    let usersObject = adminState.usersComplete.find(
      (user) => user.id === employeeId
    );
    return usersObject?.name;
  };

  return (
    <div className="reviewer-column" ref={setNodeRef}>
      <div className="timemate-admin-column-title">
        {reviewer.is_active_user ? "My Reviews" : reviewer.reviewer}
      </div>
      {reviewer.coachees.map((coachee) => {
        return (
          <div className="timemate-admin-left-column-content">
            <EmployeeApprovalCard
              key={coachee}
              employeeId={coachee}
              monthYear={`${monthNames[selectedMonth]} ${selectedYear}`}
              fullName={findFullName(coachee)}
            />
          </div>
        );
      })}
    </div>
  );
}

export default ReviewerColumn;
