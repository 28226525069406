import React, { useState } from "react";
import "./reports.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import * as Yup from "yup";
import { Formik } from "formik";
import SelectCustomized from "../../../../components/input_components/SelectCustomized";
import Button from "@mui/material/Button";
import axiosInstance from "../../../../axios/axiosConfig";
import { saveAs } from "file-saver";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import qs from "qs";
import { validationSchema, validationSchemaHelper } from "./helpers";
import { WORKSITES } from "../TimesheetCard/TimesheetCard";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function TotalHoursByActivAndUsers({
  reportName,
  endpointUrl,
  fileName,
  months,
  schemaType = "MonthYear", //possible values are MonthYear, Year, MonthYearLocation
  isMonthRequired = true,
  showLocationField = false,
}) {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const workLocations = WORKSITES;

  // define validaiton schema for form
  const validationSchema = validationSchemaHelper(schemaType);


  return (
    <div className="flex-item-accordion">
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowForwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header">
          <div className="accordion-title"> {reportName}</div>
          {/* <Typography></Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Formik
            initialValues={{
              month: "",
              year: "",
              location: [],
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              const queryParams = isMonthRequired
                ? {
                    month: values.month,
                    year: values.year,
                    locations: values.location,
                  }
                : {
                    year: values.year,
                  };

              axiosInstance
                .get(endpointUrl, {
                  responseType: "blob",
                  params: queryParams,
                  paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                  },
                })
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: "",
                  });
                  saveAs(blob, fileName);
                })
                .catch((error) => {
                  console.log("error custom report: ", error);
                });
            }}>
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <div className="flexbox-wrapper-total-hours-report">
                  {" "}
                  <div className="select-element-report">
                    {" "}
                    <SelectCustomized
                      label={isMonthRequired ? "month *" : "month"}
                      error={
                        formik.errors.month && formik.touched.month
                          ? true
                          : false
                      }
                      helperText={
                        formik.errors.month && formik.touched.month
                          ? formik.errors.month
                          : " "
                      }
                      select_options={months}
                      values={[
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "10",
                        "11",
                        "12",
                      ]}
                      value={formik.values.month}
                      onChange={formik.handleChange}
                      name="month"
                      fullWidth={true}
                      letterSpacing={0}
                    />
                  </div>
                  <div className="select-element-report">
                    {" "}
                    <SelectCustomized
                      label="year *"
                      error={
                        formik.errors.year && formik.touched.year ? true : false
                      }
                      helperText={
                        formik.errors.year && formik.touched.year
                          ? formik.errors.year
                          : " "
                      }
                      select_options={[selectedYear - 1, selectedYear]}
                      values={[selectedYear - 1, selectedYear]}
                      value={formik.values.year}
                      onChange={formik.handleChange}
                      name="year"
                      // value={10}
                      fullWidth={true}
                      letterSpacing={0}
                    />
                  </div>
                  {showLocationField && (
                    <div className="select-element-report flex-wrap">
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={workLocations}
                        value={formik.values.location}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            formik.setFieldValue("location", newValue);
                          }
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option}
                        renderOption={(props, option, { selected }) => {
                          const { key, ...optionProps } = props;
                          return (
                            <li key={key} {...optionProps}>
                              <Checkbox
                                name="location"
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="textfield-autocomplete-custom"
                            error={
                              formik.errors.location && formik.touched.location
                                ? true
                                : false
                            }
                            helperText={
                              formik.errors.location && formik.touched.location
                                ? formik.errors.location
                                : " "
                            }
                            name="location"
                            label="Location *"
                            placeholder="Location *"
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
                <div className="submit-button-wrapper">
                  <div className="report-submit-button">
                    <Button type="submit" variant="contained">
                      Download
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default TotalHoursByActivAndUsers;
