import Header from "../../components/Header";
import { getEmployees, getEmployeesRedux, getYearMonthStatus } from "./helpers";
import "./TimeMatePage.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TotalHoursByActivAndUsers from "./components/Reports/TotalHoursByActivAndUsers";
import SelectCustomized from "../../components/input_components/SelectCustomized";
import ApprovalCard from "./components/LeaveRequest/ApprovalCard";
import ReviewerColumn from "./components/EmployeeApprovalCard/ReviewerColumn";

import {
  DndContext,
  DragEndEvent,
  closestCorners,
  closestCenter,
} from "@dnd-kit/core";
import {
  handleDragEndTimesheetApprovers,
  handleUpdateDragDifferentContainer,
  handleUpdateDragSameContainer,
  handleUpdateSameContainerDrag,
  setEmployeeList,
} from "../../features/timesheetSlice";
import { useDispatch } from "react-redux";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import axiosInstance from "../../axios/axiosConfig";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function TimeMateAdminPage() {
  const adminState = useSelector((state) => state.admin);
  // const [employeesList, setEmployeesList] = useState({
  //   myApprovals: [],
  //   otherApprovals: [],
  // });
  const timesheetState = useSelector((state) => state.timesheet);

  // const [employeesList, setEmployeesList] = useState([]);
  const [employeesListLeavReq, setEmployeesListLeavReq] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedYearLeaveReq, setSelectedYearLeaveReq] = useState(
    new Date().getFullYear()
  );

  const [yearsTimesheet, setYearsTimesheet] = useState([]);
  const [yearMonthStatus, setYearMonthStatus] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    getYearMonthStatus(setYearMonthStatus);
  }, []);

  useEffect(() => {
    getEmployeesRedux(dispatch, setEmployeeList, selectedMonth, selectedYear);
  }, [selectedMonth, selectedYear, adminState.activeUser]);

  useEffect(() => {
    getEmployees(setEmployeesListLeavReq, 11, selectedYearLeaveReq);
  }, [
    selectedYearLeaveReq,
    timesheetState.employeeList,
    adminState.activeUser,
  ]);

  useEffect(() => {
    setYearsTimesheet(Object.keys(yearMonthStatus).sort((a, b) => b - a));
  }, [yearMonthStatus]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    // we need to update database
    if (active && over && active?.data?.current && over?.data?.current) {
      // get the active and over container id; this will be the name of the approver, for example Alex Müller of Fabien Roth
      let activeContainerId = active.data.current.sortable.containerId;
      let overContainerId = over.data.current.sortable.containerId;

      // if the active or over container is not found, quit
      if (!activeContainerId || !overContainerId) return;

      // update the database
      let activeUserElement = over.id;
      let coachApproverId = over.data.current.sortable.containerId;

      axiosInstance
        .put(
          "/timemate/employee-approver/",
          {
            // user_id: activeUserElement,
            coach: coachApproverId,
          },
          {
            params: {
              user_id: activeUserElement,
              coach_user_id: coachApproverId,
            },
          }
        )
        .then((response) => {
          console.log("response.data coach update", response.data);
        })
        .catch((error) => {
          console.log("error PUT /timemate/employee-approver/: ", error);
        });
    }
  };

  const handleDragOver = (event) => {
    /*
    handleDragOver is triggered when an item is dragged over another item and when an item is drag into another container. So when you drag an item into another container it often happens that 
    handleDragOver is triggered multiple times (once because dragged into new container, once because item is drag over item in new container):
      case1: when item is dragged into new container, the over.id equals the name of the reviewer (so Alex Müller, Fabien Roth, etc)
      case2: when item is dragged over another item, the over.id is a numerical id which corresponds to an employee card item over which it was dragged

    */
    const { active, over } = event;

    if (active?.data?.current && over?.data?.current && active.id !== over.id) {
      // get the active and over container id; this will be the name of the approver, for example Alex Müller of Fabien Roth
      let activeContainerId = active.data.current.sortable.containerId;
      let overContainerId = over.data.current.sortable.containerId;

      // if the active or over container is not found, quit
      if (!activeContainerId || !overContainerId) return;

      // get the index of the active and over container in the employeesListArray
      let activeContainerIndex = timesheetState.employeeList.findIndex(
        (reviewer) => reviewer.reviewer_user_id === activeContainerId
      );

      // if activerContainerIndex === -1 and activeContainerId === "Unassigned" means that the active container is the unassigned one; because the assigned container does not have a primary key of an approver associated with it
      if (activeContainerIndex === -1 && activeContainerId === "Unassigned") {
        activeContainerIndex = timesheetState.employeeList.findIndex(
          (reviewer) => reviewer.reviewer === activeContainerId
        );
      }

      let overContainerIndex = timesheetState.employeeList.findIndex(
        (reviewer) => reviewer.reviewer_user_id === overContainerId
      );

      if (overContainerIndex === -1 && overContainerId === "Unassigned") {
        overContainerIndex = timesheetState.employeeList.findIndex(
          (reviewer) => reviewer.reviewer === overContainerId
        );
      }

      // find the index of the active and over item
      const activeItemIndex = timesheetState.employeeList[
        activeContainerIndex
      ].coachees.findIndex((coachee) => coachee === active.id);
      const overItemIndex = timesheetState.employeeList[
        overContainerIndex
      ].coachees.findIndex((coachee) => coachee === over.id);

      // handle case for same container
      if (activeContainerIndex !== overContainerIndex) {
        dispatch(
          handleUpdateDragDifferentContainer({
            activeContainerIndex: activeContainerIndex,
            overContainerIndex: overContainerIndex,
            activeItemIndex: activeItemIndex,
            overItemIndex: overItemIndex,
          })
        );
        // handle case for same container
      } else {
        dispatch(
          handleUpdateDragSameContainer({
            activeContainerIndex: activeContainerIndex,
            activeItemIndex: activeItemIndex,
            overItemIndex: overItemIndex,
          })
        );
      }
    }

    // handle item drop into a container; for that case the over.id will be the name of the reviewer column (string) and not a numeric id
    if (
      typeof over?.data?.current === "undefined" &&
      typeof over?.id === "string" &&
      active?.data?.current &&
      active.id !== over.id &&
      active &&
      over
    ) {
      let activeContainerId = active.data.current.sortable.containerId;
      let overContainerId = over?.id;

      // if the active or over container is not found, quit
      if (!activeContainerId || !overContainerId) return;

      // get the index of the active and over container in the employeesListArray
      let activeContainerIndex = timesheetState.employeeList.findIndex(
        (reviewer) => reviewer.reviewer_user_id === activeContainerId
      );

      // if activerContainerIndex === -1 and activeContainerId === "Unassigned" means that the active container is the unassigned one; because the assigned container does not have a primary key of an approver associated with it
      if (activeContainerIndex === -1 && activeContainerId === "Unassigned") {
        activeContainerIndex = timesheetState.employeeList.findIndex(
          (reviewer) => reviewer.reviewer === activeContainerId
        );
      }

      let overContainerIndex = timesheetState.employeeList.findIndex(
        (reviewer) => reviewer.reviewer === overContainerId
      );

      if (overContainerIndex === -1 && overContainerId === "Unassigned") {
        overContainerIndex = timesheetState.employeeList.findIndex(
          (reviewer) => reviewer.reviewer === overContainerId
        );
      }

      // find the index of the active and over item
      const activeItemIndex = timesheetState.employeeList[
        activeContainerIndex
      ].coachees.findIndex((coachee) => coachee === active.id);
      const overItemIndex = timesheetState.employeeList[
        overContainerIndex
      ].coachees.findIndex((coachee) => coachee === over.id);

      // handle case for same container
      if (activeContainerIndex !== overContainerIndex) {
        dispatch(
          handleUpdateDragDifferentContainer({
            activeContainerIndex: activeContainerIndex,
            overContainerIndex: overContainerIndex,
            activeItemIndex: activeItemIndex,
            overItemIndex: overItemIndex,
          })
        );
      }
    }
  };

  return (
    <>
      <div className="outer-div-content-wrapper">
        <div className="back-button-navigation-grid-item-1"></div>
        <div className="main-content-wrapper">
          <Header />
          <div className="timemate-block-container">
            <div className="timemate-admin-upper-block">
              <div className="header-flexbox-wrapper-timesheets">
                <div className="timemate-admin-block-title">
                  Pending Timesheets
                </div>
                <div className="timesheet-filter-drop-down">
                  <SelectCustomized
                    label="month"
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      setSelectedMonth(e.target.value);
                    }}
                    select_options={monthNames}
                    values={Array.from(Array(12).keys())}
                    value={selectedMonth}
                    name="month"
                    letterSpacing={0}
                  />
                  <SelectCustomized
                    label="year"
                    sx={{ width: "100%" }}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    select_options={[
                      selectedYear - 1,
                      selectedYear,
                      selectedYear + 1,
                    ]}
                    values={[selectedYear - 1, selectedYear, selectedYear + 1]}
                    value={selectedYear}
                    name="year"
                    letterSpacing={0}
                  />
                </div>
              </div>
              <div className="timemate-admin-column-container">
                <DndContext
                  onDragEnd={handleDragEnd}
                  onDragOver={handleDragOver}
                  // collisionDetection={closestCenter}
                >
                  {timesheetState.employeeList.map((reviewer) => {
                    return (
                      <SortableContext
                        id={
                          reviewer.reviewer_user_id
                            ? reviewer.reviewer_user_id
                            : "Unassigned"
                        }
                        items={reviewer.coachees}>
                        <ReviewerColumn
                          key={reviewer.reviewer}
                          reviewer={reviewer}
                          selectedMonth={selectedMonth}
                          selectedYear={selectedYear}
                        />
                      </SortableContext>
                    );
                  })}
                </DndContext>
              </div>
            </div>
            <div className="timemate-admin-upper-block">
              <div className="header-flexbox-wrapper-timesheets">
                <div className="timemate-admin-block-title">
                  Pending Leave Requests
                </div>
                <div className="timesheet-filter-drop-down leave-request">
                  <SelectCustomized
                    label="year"
                    sx={{ width: "100%" }}
                    onChange={(e) => setSelectedYearLeaveReq(e.target.value)}
                    select_options={[
                      selectedYear - 1,
                      selectedYear,
                      selectedYear + 1,
                    ]}
                    values={[selectedYear - 1, selectedYear, selectedYear + 1]}
                    value={selectedYearLeaveReq}
                    name="year"
                    letterSpacing={0}
                  />
                </div>
              </div>
              <div className="timemate-admin-column-container">
                {/* maybe at some points the approver list for timesheets and leave requests will be different */}
                {/* {employeesListLeavReq.map((reviewer) => { */}
                {timesheetState.employeeList.map((reviewer) => {
                  return (
                    <div className="reviewer-column">
                      <div className="timemate-admin-column-title">
                        {reviewer.is_active_user
                          ? "My Reviews"
                          : reviewer.reviewer}
                      </div>
                      {reviewer.coachees.map((coachee) => {
                        return (
                          <div className="timemate-admin-left-column-content">
                            <ApprovalCard
                              key={coachee}
                              employeeId={coachee}
                              year={selectedYearLeaveReq}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="reports-wrapper">
              <div className="timemate-admin-block-title">Reports</div>
              <div className="accordion-reports-wrapper">
                <TotalHoursByActivAndUsers
                  months={monthNames}
                  reportName=" Total hours grouped by activity and user"
                  endpointUrl="/timemate/report-totalhours-by-act-and-user/"
                  fileName="TotalHoursByActivityAndUser.xlsx"
                />
                <TotalHoursByActivAndUsers
                  months={monthNames}
                  schemaType="MonthYearLocation"
                  showLocationField={true}
                  reportName=" Total hours {workplace} grouped by user"
                  endpointUrl="/timemate/report-onsite-hours-by-user/"
                  fileName="TotalHoursOnSiteByUser.xlsx"
                />
                <TotalHoursByActivAndUsers
                  months={monthNames}
                  reportName=" Weekend and Public holiday hours by user"
                  endpointUrl="/timemate/report-ph-and-weekend-hours-by-user/"
                  fileName="WeekendAndPHHoursByUser.xlsx"
                />
              </div>
            </div>
            <div className="reports-wrapper overtime-section">
              <div className="timemate-admin-block-title">Overtime</div>
              <div className="accordion-reports-wrapper">
                <TotalHoursByActivAndUsers
                  months={monthNames}
                  schemaType="Year"
                  isMonthRequired={false}
                  reportName=" Total hours all Users (Date, Activity Type, Activity Name, Hours)"
                  endpointUrl="/timemate/report-totalhours-by-date-and-act-user/"
                  fileName="TotalHoursByActivityNameAndUser.xlsx"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TimeMateAdminPage;
