import axiosInstance from "../../axios/axiosConfig";
import moment from "moment";

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function DaysInMonthList({ dateString }) {
  const [month, year] = dateString.split(" ");

  // Create a Date object for the first day of the month
  const firstDayOfMonth = new Date(`${month} 1, ${year}`);

  // Get the last day of the month
  const lastDayOfMonth = new Date(
    firstDayOfMonth.getFullYear(),
    firstDayOfMonth.getMonth() + 1,
    0
  );

  // Array to store the days
  const dates = [];

  // Loop through each day and add it to the array
  for (let day = 1; day <= lastDayOfMonth.getDate(); day++) {
    const currentDate = new Date(
      firstDayOfMonth.getFullYear(),
      firstDayOfMonth.getMonth(),
      day
    );
    const formattedDate = currentDate.toLocaleDateString("GB"); // Format as 'YYYY-MM-DD'
    dates.push(formattedDate);
  }
  return dates;
}

export const getUserFullNameReturn = async (employeeId) => {
  try {
    let url = "/cvapp/employee-profile/";
    if (employeeId) url = url + `?managed_employee=${employeeId}`;

    const responseDjango = await axiosInstance.get(url);
    const UserName =
      capitalizeFirstLetter(responseDjango.data.firstName) +
      " " +
      capitalizeFirstLetter(responseDjango.data.lastName);
    return UserName;
  } catch (error) {
    console.error("Error fetching data from Django:", error);
  }
};

export const getUserFullName = async (setUserFullName, employeeId) => {
  try {
    let url = "/cvapp/employee-profile/";
    if (employeeId) url = url + `?managed_employee=${employeeId}`;

    const responseDjango = await axiosInstance.get(url);
    const UserName =
      capitalizeFirstLetter(responseDjango.data.firstName) +
      " " +
      capitalizeFirstLetter(responseDjango.data.lastName);
    setUserFullName(UserName);
    return UserName;
  } catch (error) {
    console.error("Error fetching data from Django:", error);
  }
};

export const getFilteredTimesheetData = (
  setTimesheetData,
  dates,
  employeeId
) => {
  let start_date = null;
  let end_date = null;
  // check that start date is valid
  if (dates[0]) {
    start_date = moment(dates[0]).format("DD-MM-yyyy");
  }

  // check that end date is valid
  if (dates[1]) {
    end_date = moment(dates[1]).format("DD-MM-yyyy");
  } else {
    end_date = start_date;
  }

  // it both start and end date are valid make request
  if (start_date && end_date) {
    // request to get aggregated data
    axiosInstance
      .get("/timemate/employeetimesheet/", {
        params: {
          managed_employee: employeeId,
          start_date: start_date,
          end_date: end_date,
        },
      })
      .then((response) => {
        setTimesheetData(response.data);
      })
      .catch((error) => {
        console.error("Error /timemate/aggregated-hours-by-user/: ", error);
      });
  }
};

export const getAggregatedTimesheetData = (
  setAggregatedData,
  dateString,
  employeeId
) => {
  // construct first and last date of month
  const [month, year] = dateString.split(" ");
  const firstDayOfMonth = new Date(`${month} 1, ${year}`);
  // Get the last day of the month
  const lastDayOfMonth = new Date(
    firstDayOfMonth.getFullYear(),
    firstDayOfMonth.getMonth() + 1,
    0
  );
  const start_date = moment(firstDayOfMonth).format("DD-MM-yyyy");
  const end_date = moment(lastDayOfMonth).format("DD-MM-yyyy");

  // request to get aggregated data
  axiosInstance
    .get("/timemate/aggregated-hours-by-user/", {
      params: {
        managed_employee: employeeId,
        start_date: start_date,
        end_date: end_date,
      },
    })
    .then((response) => {
      setAggregatedData(response.data);
    })
    .catch((error) => {
      console.error("Error /timemate/aggregated-hours-by-user/: ", error);
    });
};

export const getTimesheetData = async (
  setTimesheetData,
  dateString,
  employeeId
) => {
  try {
    if (dateString !== "") {
      const [month, year] = dateString.split(" ");
      const firstDayOfMonth = new Date(`${month} 1, ${year}`);
      // Get the last day of the month
      const lastDayOfMonth = new Date(
        firstDayOfMonth.getFullYear(),
        firstDayOfMonth.getMonth() + 1,
        0
      );
      const start_date = moment(firstDayOfMonth).format("DD-MM-yyyy");
      const end_date = moment(lastDayOfMonth).format("DD-MM-yyyy");

      let url = `/timemate/employeetimesheet/?start_date=${start_date}&end_date=${end_date}`;
      if (employeeId) url = url + `&managed_employee=${employeeId}`;

      const responseDjango = await axiosInstance.get(url);
      setTimesheetData(responseDjango.data);
      return responseDjango.data;
    }
  } catch (error) {
    console.error("Error fetching Timesheet data from Django:", error);
  }
};

export const getMonthStatus = async (month, employeeId, setTimesheetStatus) => {
  try {
    let _month = new Date(`${month.month} 1, ${month.year}`);

    _month = moment(_month).format("DD-MM-yyyy");

    let url = `/timemate/employeetimesheetstatusmangement/?month=${_month}`;
    if (employeeId) url = url + `&managed_employee=${employeeId}`;

    const responseDjango = await axiosInstance.get(url);
    if (setTimesheetStatus) setTimesheetStatus(responseDjango.data.status);
    return responseDjango.data.status;
  } catch (error) {
    console.error(
      "Error fetching Timesheet Month Status data from Django:",
      error
    );
  }
};

export const updateMonthStatus = async (
  month,
  status,
  employeeId,
  comment,
  monitorTimesheet,
  setMonitorTimesheet,
  is_reopen = false
) => {
  try {
    const firstDayOfMonth = new Date(`${month.month} 1, ${month.year}`);
    const date = firstDayOfMonth
      .toLocaleDateString("en-GB")
      .replace(/\//g, "-");

    let url = "/timemate/employeetimesheetstatusmangement/";
    const payload = {
      status: status,
      month: date,
      managed_employee: employeeId,
      comment: comment,
      is_reopen: is_reopen,
    };
    const responseDjango = await axiosInstance.post(url, payload);
    setMonitorTimesheet(!monitorTimesheet);
  } catch (error) {
    console.error(
      "Error fetching Timesheet Month Status data from Django:",
      error
    );
  }
};

export function formatDate(date) {
  const options = { month: "long", year: "numeric" };
  let dateString = new Date(date).toLocaleString(undefined, options);
  const [month, year] = dateString.split(" ");
  const firstDayOfMonth = new Date(`${month} 1, ${year}`);
  // Get the last day of the month
  const lastDayOfMonth = new Date(
    firstDayOfMonth.getFullYear(),
    firstDayOfMonth.getMonth() + 1,
    0
  );

  const start_date = moment(firstDayOfMonth).format("DD-MM-yyyy");
  const end_date = moment(lastDayOfMonth).format("DD-MM-yyyy");

  // check if start_date and end_date are valid
  if (
    moment(start_date, "DD-MM-yyyy", true).isValid() &&
    moment(end_date, "DD-MM-yyyy", true).isValid()
  ) {
    return new Date(date).toLocaleString(undefined, options);
  } else {
    return moment(date).format("MMMM YYYY");
  }

  // return new Date(date).toLocaleString(undefined, options);
}

export const getEmployees = async (setEmployeesList, month, year) => {
  try {
    const responseDjango = await axiosInstance.get(
      "/timemate/coachedemployees/",
      {
        params: {
          month: month + 1,
          year: year,
        },
      }
    );
    setEmployeesList(responseDjango.data);
  } catch (error) {
    console.error("Error fetching data from Django:", error);
  }
};

export const getEmployeesRedux = async (
  dispatch,
  setEmployeesList,
  month,
  year
) => {
  try {
    const responseDjango = await axiosInstance.get(
      "/timemate/coachedemployees/",
      {
        params: {
          month: month + 1,
          year: year,
        },
      }
    );
    dispatch(setEmployeesList(responseDjango.data));
  } catch (error) {
    console.error("Error fetching data from Django:", error);
  }
};

export const getYearMonthStatus = async (setYearMonthStatus) => {
  try {
    const responseDjango = await axiosInstance.get(
      "/timemate/timesheetadminyearmonthstatus/"
    );
    setYearMonthStatus(responseDjango.data);
  } catch (error) {
    console.error("Error fetching data from Django:", error);
  }
};

export const checkYearStatus = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] !== "Done") {
        return "Open";
      }
    }
  }
  return "Done";
};
