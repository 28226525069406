import React, { useState, useRef, useEffect } from "react";
import PhilicoLogo from "../assets/philico_logo_initials.png";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { useSelector, useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import axiosInstance from "../axios/axiosConfig";
import CircularProgress from "@mui/material/CircularProgress";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import EmailSignatureIcon from "../assets/email_signature_icon.svg";
import {
  changeLinkedinUrl,
  initalizeLayout as initializeLayoutPage1,
} from "../features/profileinfoSlice";
import { initializeLayout as initializeLayoutpage2 } from "../features/personalinfoSlice";
import { initializeLayout as initializeLayoutPage3 } from "../features/internalinfoSlice";
import { initalizeLayout as initializeLayoutpage4 } from "../features/educationSlice";
import { initalizeLayout as initializeLayoutpage5 } from "../features/experienceSlice";
import { initializeLayout as initializeLayoutpage6 } from "../features/descriptionSlice";
import {
  changeImagePath,
  changeNationality,
  initalizeAddressFields,
  initalizeContactDetails,
  initializeCompleteAddressSet,
} from "../features/profileinfoSlice";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import SvgIcon from "@mui/material/SvgIcon";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import axios from "axios";
import SelectCustomized from "./input_components/SelectCustomized";
import hamburgerIcon from "../assets/hamburgerIconTest2.svg";
import crossIcon from "../assets/cross_icon_menu.svg";
import {
  setAlertMessage,
  setIsFailAlertOpen,
  setIsSuccessAlertOpen,
} from "../features/alertMessageSlice";
import {
  imageFailAlert,
  imageSuccessAlert,
  htmlToClipboard,
  resetLayoutFailAlert,
  resetLayoutSuccessAlert,
  htmlToClipboardWWWInvalidUrl,
} from "./AlertMessages";
import Dialog from "@mui/material/Dialog";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  SelectResetFunction,
  initializeCardLayouts,
} from "./CardsLayoutsHelperFunctions";
import LogoutIcon from "@mui/icons-material/Logout";
import LogoutDialog from "./LogoutDialog";
import { useNavigate } from "react-router-dom";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Box from "@mui/material/Box";
import {
  changeActiveUser,
  changeUserRole,
  handleUserRoleChange,
  initalizeActiveUsers,
  initalizeInactiveUsers,
  initalizeUsersComplete,
  initalizeUsersId,
  initalizeUsersName,
  setPageLocationString,
} from "../features/adminSlice";
import { initializeCvSummary } from "../features/cvSummarySlice";
import { initializeOtherExperience } from "../features/otherExperience";
import { initializeSelectedExperience } from "../features/selectedExperiences";
import { initalizeLanguageSkills } from "../features/languageSkillSlice";
import { initalizeExperiences } from "../features/cvProjectExperience";
import { initalizeEmploymentHistory } from "../features/employmentHistory";
import { initalizeDegrees } from "../features/cvDegrees";
import { initalizeCertificates } from "../features/cvCertificates";
import {
  changeExpensesAccount,
  changeIsSalaySameAsExpenses,
  changeSalaryAccount,
  handleAHVChange,
  handlePlaceOfOriginChange,
  handlePositionChange,
  handleStartDateChange,
  handleSubcontractorChange,
  handleWitholdingTaxChange,
  initalizeIdentityDocuments,
  setBackDropOpenPhilicoLogo,
} from "../features/internalinfoSlice";
import {
  handleMaritalStatusChange,
  initalizeLinkedContacts,
} from "../features/personalinfoSlice";
import { resetReduxState } from "../features/resetState";
import copy from "copy-html-to-clipboard";
import { initalizeDataWorkflowObject } from "../features/cvDataWorkflowSlice";
import HamburgerMenu from "./HamburgerMenu";

// let cvAppActions = [
//   {
//     icon: (
//       <SvgIcon viewBox="0 0 17 17">
//         <path
//           d="M6.375 2.125C6.90036 2.125 7.41392 2.28079 7.85073 2.57266C8.28755 2.86453 8.62801 3.27938 8.82906 3.76475C9.0301 4.25011 9.0827 4.7842 8.98021 5.29946C8.87772 5.81472 8.62474 6.28802 8.25325 6.6595C7.88177 7.03099 7.40847 7.28397 6.89321 7.38646C6.37795 7.48895 5.84386 7.43635 5.3585 7.23531C4.87313 7.03426 4.45828 6.6938 4.16641 6.25698C3.87454 5.82017 3.71875 5.30661 3.71875 4.78125C3.71875 4.07677 3.9986 3.40114 4.49675 2.903C4.99489 2.40485 5.67052 2.125 6.375 2.125ZM6.375 1.0625C5.6395 1.0625 4.92052 1.2806 4.30897 1.68922C3.69743 2.09784 3.22079 2.67863 2.93932 3.35815C2.65786 4.03766 2.58422 4.78538 2.7277 5.50674C2.87119 6.22811 3.22537 6.89073 3.74545 7.4108C4.26552 7.93088 4.92814 8.28506 5.64951 8.42855C6.37087 8.57203 7.11859 8.49839 7.7981 8.21693C8.47762 7.93546 9.05841 7.45882 9.46703 6.84728C9.87565 6.23573 10.0938 5.51675 10.0938 4.78125C10.0938 3.79498 9.70195 2.8491 9.00455 2.1517C8.30715 1.4543 7.36127 1.0625 6.375 1.0625ZM11.6875 15.9375H10.625V13.2812C10.625 12.5768 10.3451 11.9011 9.847 11.403C9.34886 10.9049 8.67323 10.625 7.96875 10.625H4.78125C4.07677 10.625 3.40114 10.9049 2.903 11.403C2.40485 11.9011 2.125 12.5768 2.125 13.2812V15.9375H1.0625V13.2812C1.0625 12.295 1.4543 11.3491 2.1517 10.6517C2.8491 9.9543 3.79498 9.5625 4.78125 9.5625H7.96875C8.95502 9.5625 9.9009 9.9543 10.5983 10.6517C11.2957 11.3491 11.6875 12.295 11.6875 13.2812V15.9375ZM11.6875 2.125H17V3.1875H11.6875V2.125ZM11.6875 4.78125H17V5.84375H11.6875V4.78125ZM11.6875 7.4375H15.4062V8.5H11.6875V7.4375Z"
//           fill="#004170"
//         />
//       </SvgIcon>
//     ),
//     name: "Personal Information",
//     url: "/profile",
//   },
//   {
//     icon: (
//       <SvgIcon viewBox="0 0 16 20">
//         <path
//           d="M5.80004 6.34999C6.36004 6.34999 6.81004 5.89999 6.81004 5.33999C6.81004 4.77999 6.36004 4.32999 5.80004 4.32999C5.24004 4.32999 4.79004 4.77999 4.79004 5.33999C4.79004 5.89999 5.24004 6.34999 5.80004 6.34999Z"
//           fill="#004170"
//         />
//         <path
//           fill-rule="evenodd"
//           clip-rule="evenodd"
//           d="M7.83 8.55C7.83 7.47 6.92 6.69 5.8 6.69C4.68 6.69 3.77 7.47 3.77 8.55V9.06C3.77 9.15 3.81 9.24 3.87 9.3C3.93 9.36 4.02 9.4 4.11 9.4H7.49C7.58 9.4 7.67 9.36 7.73 9.3C7.79 9.24 7.83 9.15 7.83 9.06V8.55ZM3.75 11.5C3.75 11.3011 3.82902 11.1103 3.96967 10.9697C4.11032 10.829 4.30109 10.75 4.5 10.75H11.5C11.6989 10.75 11.8897 10.829 12.0303 10.9697C12.171 11.1103 12.25 11.3011 12.25 11.5C12.25 11.6989 12.171 11.8897 12.0303 12.0303C11.8897 12.171 11.6989 12.25 11.5 12.25H4.5C4.30109 12.25 4.11032 12.171 3.96967 12.0303C3.82902 11.8897 3.75 11.6989 3.75 11.5ZM3.75 14.5C3.75 14.3011 3.82902 14.1103 3.96967 13.9697C4.11032 13.829 4.30109 13.75 4.5 13.75H11.5C11.6989 13.75 11.8897 13.829 12.0303 13.9697C12.171 14.1103 12.25 14.3011 12.25 14.5C12.25 14.6989 12.171 14.8897 12.0303 15.0303C11.8897 15.171 11.6989 15.25 11.5 15.25H4.5C4.30109 15.25 4.11032 15.171 3.96967 15.0303C3.82902 14.8897 3.75 14.6989 3.75 14.5Z"
//           fill="#004170"
//         />
//         <path
//           fill-rule="evenodd"
//           clip-rule="evenodd"
//           d="M0.5 2.5C0.5 1.398 1.398 0.5 2.5 0.5H9.19C9.752 0.5 10.282 0.738 10.655 1.131L10.661 1.138L14.973 5.84C15.332 6.223 15.5 6.724 15.5 7.2V17.5C15.5 18.602 14.602 19.5 13.5 19.5H2.5C1.398 19.5 0.5 18.602 0.5 17.5V2.5ZM9.189 2.5H2.5V17.5H13.5V7.192L9.204 2.507L9.201 2.506C9.19736 2.50335 9.1933 2.50133 9.189 2.5Z"
//           fill="#004170"
//         />
//         <path
//           fill-rule="evenodd"
//           clip-rule="evenodd"
//           d="M9.18994 0.5C9.45516 0.5 9.70951 0.605357 9.89705 0.792893C10.0846 0.98043 10.1899 1.23478 10.1899 1.5V6.2H14.4999C14.6313 6.2 14.7613 6.22587 14.8826 6.27612C15.004 6.32638 15.1142 6.40003 15.207 6.49289C15.2999 6.58575 15.3736 6.69599 15.4238 6.81732C15.4741 6.93864 15.4999 7.06868 15.4999 7.2C15.4999 7.33132 15.4741 7.46136 15.4238 7.58268C15.3736 7.70401 15.2999 7.81425 15.207 7.90711C15.1142 7.99997 15.004 8.07362 14.8826 8.12388C14.7613 8.17413 14.6313 8.2 14.4999 8.2H9.18994C8.92472 8.2 8.67037 8.09464 8.48283 7.90711C8.2953 7.71957 8.18994 7.46522 8.18994 7.2V1.5C8.18994 1.23478 8.2953 0.98043 8.48283 0.792893C8.67037 0.605357 8.92472 0.5 9.18994 0.5Z"
//           fill="#004170"
//         />
//       </SvgIcon>
//     ),
//     name: "Create CV",
//     url: "/create-cv",
//   },
//   {
//     icon: (
//       <SvgIcon viewBox="0 0 17 17">
//         <path
//           d="M6.375 2.125C6.90036 2.125 7.41392 2.28079 7.85073 2.57266C8.28755 2.86453 8.62801 3.27938 8.82906 3.76475C9.0301 4.25011 9.0827 4.7842 8.98021 5.29946C8.87772 5.81472 8.62474 6.28802 8.25325 6.6595C7.88177 7.03099 7.40847 7.28397 6.89321 7.38646C6.37795 7.48895 5.84386 7.43635 5.3585 7.23531C4.87313 7.03426 4.45828 6.6938 4.16641 6.25698C3.87454 5.82017 3.71875 5.30661 3.71875 4.78125C3.71875 4.07677 3.9986 3.40114 4.49675 2.903C4.99489 2.40485 5.67052 2.125 6.375 2.125ZM6.375 1.0625C5.6395 1.0625 4.92052 1.2806 4.30897 1.68922C3.69743 2.09784 3.22079 2.67863 2.93932 3.35815C2.65786 4.03766 2.58422 4.78538 2.7277 5.50674C2.87119 6.22811 3.22537 6.89073 3.74545 7.4108C4.26552 7.93088 4.92814 8.28506 5.64951 8.42855C6.37087 8.57203 7.11859 8.49839 7.7981 8.21693C8.47762 7.93546 9.05841 7.45882 9.46703 6.84728C9.87565 6.23573 10.0938 5.51675 10.0938 4.78125C10.0938 3.79498 9.70195 2.8491 9.00455 2.1517C8.30715 1.4543 7.36127 1.0625 6.375 1.0625ZM11.6875 15.9375H10.625V13.2812C10.625 12.5768 10.3451 11.9011 9.847 11.403C9.34886 10.9049 8.67323 10.625 7.96875 10.625H4.78125C4.07677 10.625 3.40114 10.9049 2.903 11.403C2.40485 11.9011 2.125 12.5768 2.125 13.2812V15.9375H1.0625V13.2812C1.0625 12.295 1.4543 11.3491 2.1517 10.6517C2.8491 9.9543 3.79498 9.5625 4.78125 9.5625H7.96875C8.95502 9.5625 9.9009 9.9543 10.5983 10.6517C11.2957 11.3491 11.6875 12.295 11.6875 13.2812V15.9375ZM11.6875 2.125H17V3.1875H11.6875V2.125ZM11.6875 4.78125H17V5.84375H11.6875V4.78125ZM11.6875 7.4375H15.4062V8.5H11.6875V7.4375Z"
//           fill="#004170"
//         />
//       </SvgIcon>
//     ),
//     name: "Skills Matrix",
//     url: "/skills-matrix",
//   },
//   {
//     icon: (
//       <SvgIcon viewBox="0 0 17 17">
//         <path
//           d="M6.375 2.125C6.90036 2.125 7.41392 2.28079 7.85073 2.57266C8.28755 2.86453 8.62801 3.27938 8.82906 3.76475C9.0301 4.25011 9.0827 4.7842 8.98021 5.29946C8.87772 5.81472 8.62474 6.28802 8.25325 6.6595C7.88177 7.03099 7.40847 7.28397 6.89321 7.38646C6.37795 7.48895 5.84386 7.43635 5.3585 7.23531C4.87313 7.03426 4.45828 6.6938 4.16641 6.25698C3.87454 5.82017 3.71875 5.30661 3.71875 4.78125C3.71875 4.07677 3.9986 3.40114 4.49675 2.903C4.99489 2.40485 5.67052 2.125 6.375 2.125ZM6.375 1.0625C5.6395 1.0625 4.92052 1.2806 4.30897 1.68922C3.69743 2.09784 3.22079 2.67863 2.93932 3.35815C2.65786 4.03766 2.58422 4.78538 2.7277 5.50674C2.87119 6.22811 3.22537 6.89073 3.74545 7.4108C4.26552 7.93088 4.92814 8.28506 5.64951 8.42855C6.37087 8.57203 7.11859 8.49839 7.7981 8.21693C8.47762 7.93546 9.05841 7.45882 9.46703 6.84728C9.87565 6.23573 10.0938 5.51675 10.0938 4.78125C10.0938 3.79498 9.70195 2.8491 9.00455 2.1517C8.30715 1.4543 7.36127 1.0625 6.375 1.0625ZM11.6875 15.9375H10.625V13.2812C10.625 12.5768 10.3451 11.9011 9.847 11.403C9.34886 10.9049 8.67323 10.625 7.96875 10.625H4.78125C4.07677 10.625 3.40114 10.9049 2.903 11.403C2.40485 11.9011 2.125 12.5768 2.125 13.2812V15.9375H1.0625V13.2812C1.0625 12.295 1.4543 11.3491 2.1517 10.6517C2.8491 9.9543 3.79498 9.5625 4.78125 9.5625H7.96875C8.95502 9.5625 9.9009 9.9543 10.5983 10.6517C11.2957 11.3491 11.6875 12.295 11.6875 13.2812V15.9375ZM11.6875 2.125H17V3.1875H11.6875V2.125ZM11.6875 4.78125H17V5.84375H11.6875V4.78125ZM11.6875 7.4375H15.4062V8.5H11.6875V7.4375Z"
//           fill="#004170"
//         />
//       </SvgIcon>
//     ),
//     name: "Skills Matrix Overview",
//     url: "/skills-matrix-ov",
//   },
//   {
//     icon: (
//       <SvgIcon viewBox="0 0 17 17">
//         <path
//           d="M6.375 2.125C6.90036 2.125 7.41392 2.28079 7.85073 2.57266C8.28755 2.86453 8.62801 3.27938 8.82906 3.76475C9.0301 4.25011 9.0827 4.7842 8.98021 5.29946C8.87772 5.81472 8.62474 6.28802 8.25325 6.6595C7.88177 7.03099 7.40847 7.28397 6.89321 7.38646C6.37795 7.48895 5.84386 7.43635 5.3585 7.23531C4.87313 7.03426 4.45828 6.6938 4.16641 6.25698C3.87454 5.82017 3.71875 5.30661 3.71875 4.78125C3.71875 4.07677 3.9986 3.40114 4.49675 2.903C4.99489 2.40485 5.67052 2.125 6.375 2.125ZM6.375 1.0625C5.6395 1.0625 4.92052 1.2806 4.30897 1.68922C3.69743 2.09784 3.22079 2.67863 2.93932 3.35815C2.65786 4.03766 2.58422 4.78538 2.7277 5.50674C2.87119 6.22811 3.22537 6.89073 3.74545 7.4108C4.26552 7.93088 4.92814 8.28506 5.64951 8.42855C6.37087 8.57203 7.11859 8.49839 7.7981 8.21693C8.47762 7.93546 9.05841 7.45882 9.46703 6.84728C9.87565 6.23573 10.0938 5.51675 10.0938 4.78125C10.0938 3.79498 9.70195 2.8491 9.00455 2.1517C8.30715 1.4543 7.36127 1.0625 6.375 1.0625ZM11.6875 15.9375H10.625V13.2812C10.625 12.5768 10.3451 11.9011 9.847 11.403C9.34886 10.9049 8.67323 10.625 7.96875 10.625H4.78125C4.07677 10.625 3.40114 10.9049 2.903 11.403C2.40485 11.9011 2.125 12.5768 2.125 13.2812V15.9375H1.0625V13.2812C1.0625 12.295 1.4543 11.3491 2.1517 10.6517C2.8491 9.9543 3.79498 9.5625 4.78125 9.5625H7.96875C8.95502 9.5625 9.9009 9.9543 10.5983 10.6517C11.2957 11.3491 11.6875 12.295 11.6875 13.2812V15.9375ZM11.6875 2.125H17V3.1875H11.6875V2.125ZM11.6875 4.78125H17V5.84375H11.6875V4.78125ZM11.6875 7.4375H15.4062V8.5H11.6875V7.4375Z"
//           fill="#004170"
//         />
//       </SvgIcon>
//     ),
//     name: "TimeMate",
//     url: "/timemate",
//   },
// ];

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function Header() {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [dialogActivateUserOpen, setDialogActiveUsersOpen] = useState(false);
  const [dialogManageUserRolesOpen, setDialogManageUserRolesOpen] =
    useState(false);
  const [mousePos, setMousePos] = useState({});
  const [activeUsersLoaded, setActiveUsersLoaded] = useState(false);
  const [inactiveUsersLoaded, setInactiveUsersLoaded] = useState(false);

  const pageLocation = useLocation();
  const navigate = useNavigate();

  // get redux profileinfostate
  const profileIntoState = useSelector((state) => state.profileinfo);
  const internalInfoState = useSelector((state) => state.internalinfo);
  const adminState = useSelector((state) => state.admin);

  const [checked, setChecked] = React.useState([]);
  const leftChecked = intersection(checked, adminState.activeUsers);
  const rightChecked = intersection(checked, adminState.inactiveUsers);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [dialogResetLayoutOpen, setDialogResetLayoutOpen] = useState(false);
  const [dialogChangeUserOpen, setDialogChangeUserOpen] = useState(false);
  const [dialogLogoutOpen, setDialogLogoutOpen] = useState(false);

  // define state that tracks if image is uploaded
  const [isImageUploading, setIsImageUploading] = useState(false);

  const validationSchema = Yup.object().shape({
    user: Yup.string().required("You must select a user!"),
  });

  // formik hook for handling form
  const formik = useFormik({
    initialValues: {
      user: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // reset redux toolkit state
      dispatch(resetReduxState());

      // close the form
      handleDialogChangeUserClose();

      // open loading backdrop
      setBackdropOpen(true);

      setTimeout(() => {
        setBackdropOpen(false);
      }, 3000);

      // change the active user
      dispatch(changeActiveUser(values.user));

      localStorage.setItem("activeUser", values.user);

      // get cards layout of new user
      try {
        // get card layouts from db
        var responseCardLayouts = await axiosInstance.get(
          "/cvapp/cards-layout/"
        );

        let extractedLayoutPage1 = initializeCardLayouts(
          responseCardLayouts.data,
          ["contactCard", "addressCard"]
        );

        let extractedLayoutPage2 = initializeCardLayouts(
          responseCardLayouts.data,
          ["relationshipCard", "languageSkillsCard"]
        );

        let extractedLayoutPage3 = initializeCardLayouts(
          responseCardLayouts.data,
          [
            "idDocumentCard",
            "bankInfoCard",
            "relationshipCard",
            "contractInfoCard",
          ]
        );

        let extractedLayoutPage4 = initializeCardLayouts(
          responseCardLayouts.data,
          ["educationCard"]
        );

        let extractedLayoutPage5 = initializeCardLayouts(
          responseCardLayouts.data,
          ["experienceCard"]
        );

        let extractedLayoutPage6 = initializeCardLayouts(
          responseCardLayouts.data,
          ["descriptionCard"]
        );

        dispatch(initializeLayoutPage1(extractedLayoutPage1));
        dispatch(initializeLayoutpage2(extractedLayoutPage2));
        dispatch(initializeLayoutPage3(extractedLayoutPage3));
        dispatch(initializeLayoutpage4(extractedLayoutPage4));
        dispatch(initializeLayoutpage5(extractedLayoutPage5));
        dispatch(initializeLayoutpage6(extractedLayoutPage6));
      } catch (err) {
        console.log("error in fetching card layouts");
      }

      // if user is change we need to make request to db to get data of new active user
      // request for first name, last name, email, phone, etc.
      axiosInstance
        .get("/cvapp/employee-profile/")
        .then((response) => {
          // destructure the array with only the properties that you need; we create an arrow function which we then directly call with the response.data object as an argument
          const contactDetails = (({
            firstName,
            lastName,
            email,
            phone,
            countryCode,
            birthDate,
          }) => ({
            firstName,
            lastName,
            email,
            phone,
            countryCode,
            birthDate,
          }))(response.data);
          // initalize the contact details state with the db values
          dispatch(initalizeContactDetails(contactDetails));
          dispatch(changeNationality(response.data.nationality));
          dispatch(changeLinkedinUrl(response.data.linkedinUrl));
          dispatch(changeImagePath(response.data.imageURL));
        })
        .catch((error) => {
          console.log("error updating first name contact details: ", error);
        });

      // request for city, country, streetNr, ...
      axiosInstance
        .get("/cvapp/employee-profile/")
        .then((response) => {
          // destructure the array with only the properties that you need; we create an arrow function which we then directly call with the response.data object as an argument
          const addressFields = (({
            city,
            country,
            street,
            streetNr,
            zip,
          }) => ({
            city,
            country,
            street,
            streetNr,
            zip,
          }))(response.data);

          dispatch(initalizeAddressFields(addressFields));
          dispatch(initializeCompleteAddressSet(addressFields));
        })
        .catch((error) => {
          console.log("error GET request: ", error);
        });

      // request for cv summaries
      axiosInstance.get("/cvapp/cv-summaries/").then((response) => {
        dispatch(initializeCvSummary(response.data));
      });

      // request for skills
      axiosInstance.get("/cvapp/skills/").then((response) => {
        let keySkills = [];
        let otherSkills = [];

        response.data.map((skill) => {
          if (skill.skillType === "Key") {
            keySkills.push(skill);
          } else if (skill.skillType === "Other") {
            otherSkills.push(skill);
          }
        });

        dispatch(initializeOtherExperience(otherSkills));
        dispatch(initializeSelectedExperience(keySkills));
      });

      // request for language skills
      axiosInstance.get("/cvapp/language-skills").then((response) => {
        dispatch(initalizeLanguageSkills(response.data));
      });

      // request for project experience
      axiosInstance.get("/cvapp/experiences/").then((response) => {
        dispatch(initalizeExperiences(response.data));
      });

      // request for employment history
      axiosInstance.get("/cvapp/employment-history/").then((response) => {
        dispatch(initalizeEmploymentHistory(response.data));
      });

      // request for degrees
      axiosInstance.get("/cvapp/degrees/").then((response) => {
        dispatch(initalizeDegrees(response.data));
      });

      // request for certificates
      axiosInstance.get("/cvapp/certificates/").then((response) => {
        dispatch(initalizeCertificates(response.data));
      });

      // get identity documents
      axiosInstance
        .get("/cvapp/identity-documents/")
        .then((response) => {
          dispatch(initalizeIdentityDocuments(response.data));
        })
        .catch((error) => {
          console.log("Error get request identitiy documents: ", error);
        });

      // get bank account data
      axiosInstance
        .get("/cvapp/bank-accounts/")
        .then((response) => {
          response.data.map((account) => {
            if (account.accountType === "Salary") {
              dispatch(changeSalaryAccount(account));
            } else if (account.accountType === "Expenses") {
              dispatch(changeExpensesAccount(account));
              dispatch(changeIsSalaySameAsExpenses(account.isExpensesAccount));
            }
          });
        })
        .catch((error) => {
          console.log("error GET request: ", error);
        });

      // get linked contacts
      axiosInstance
        .get("/cvapp/linked-contacts/")
        .then((response) => {
          dispatch(initalizeLinkedContacts(response.data));
        })
        .catch((error) => {
          console.log("error GET request: ", error);
        });

      //get position within philico, start date, subcontractor status, marital status
      axiosInstance
        .get("/cvapp/employee-profile/")
        .then((response) => {
          dispatch(handlePositionChange(response.data.positionWithinPhilico));
          dispatch(handleStartDateChange(response.data.startDatePhilico));
          dispatch(handleSubcontractorChange(response.data.subcontractor));
          dispatch(handleMaritalStatusChange(response.data.maritalStatus));
          dispatch(handleAHVChange(response.data.ahv));
          dispatch(handlePlaceOfOriginChange(response.data.placeOfOrigin));
          dispatch(handleWitholdingTaxChange(response.data.withholdingTax));
        })
        .catch((error) => {
          console.log("error initializing contract card: ", error);
        });

      // get cv approval workflow data
      axiosInstance
        .get("/cvapp/cv-data-workflow/")
        .then((response) => {
          dispatch(initalizeDataWorkflowObject(response.data));
        })
        .catch((error) => {
          console.log("error initalizing cv data workflow");
        });

      // close avatar menu
      setAnchorEl(null);
    },
  });

  // create dispatch variable to access state reducers later
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);

  const handleMouseOver = (e) => {
    if (anchorEl !== e.currentTarget) {
      setAnchorEl(e.currentTarget);
      e.currentTarget.focus();
    }
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
      // event.preventDefault();
      // event.stopPropagation();
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const avatarDiv = document.getElementById("avatar-bounding-div");
    const avatarButton = document.getElementById("avatar-button");
    const menu = document.getElementById("account-menu")?.children[2];

    let borderBottom = menu
      ? menu.getBoundingClientRect().bottom
      : avatarDiv.getBoundingClientRect().bottom;

    let borderLeft = menu
      ? menu.getBoundingClientRect().left
      : avatarDiv.getBoundingClientRect().left;
    if (
      // handle case when mouse is to the left of avatar and outside of menu
      (mousePos.x < avatarDiv.getBoundingClientRect().left &&
        mousePos.y < avatarButton.getBoundingClientRect().bottom + 6) ||
      // handle case where mouse goes out on top
      mousePos.y < avatarDiv.getBoundingClientRect().top - 4 ||
      // handle case where mouse goes out on right side of avatar
      mousePos.x > avatarDiv.getBoundingClientRect().right + 6 ||
      // handle case where mouse is below bottom border of menu
      mousePos.y > borderBottom ||
      // handle case where mouse is outside of left border of menu
      mousePos.x < borderLeft - 2
    ) {
      setAnchorEl(null);
      avatarDiv.focus(); //this focus is needed, otherwise we will jump to the last focused element, which could be for example the certifications field --> unwanted scroll to bottom of page!
    }
  }, [mousePos]);

  const handleDialogResetLayoutClose = () => {
    setDialogResetLayoutOpen(false);
  };

  const handleDialogManageUserRolesClose = () => {
    setDialogManageUserRolesOpen(false);
  };

  const handleDialogChangeUserClose = () => {
    setDialogChangeUserOpen(false);
  };

  const handleLogoutDialogClose = () => {
    setDialogLogoutOpen(false);
  };

  const handleDialogActivateUserClose = () => {
    setDialogActiveUsersOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    navigate("/");
  };

  useEffect(() => {
    switch (pageLocation.pathname) {
      case "/create-cv":
        dispatch(setPageLocationString("Create CV"));
        break;
      case "/profile":
        dispatch(setPageLocationString("Personal Information"));
        break;
      case "/skills-matrix":
        dispatch(setPageLocationString("Skills Matrix"));
        break;
      case "/skills-matrix-ov":
        dispatch(setPageLocationString("Skills Matrix Overview"));
        break;
      case "/timemate":
        dispatch(setPageLocationString("TimeMate"));
        break;
      case "/timemateadmin":
        dispatch(setPageLocationString("TimeMate Admin"));
        break;
      case "/leave-request":
        dispatch(setPageLocationString("Leave Request"));
        break;
    }
  }, [pageLocation]);

  const handleCardsLayoutReset = () => {
    let resetFunction = SelectResetFunction(pageLocation.pathname);
    dispatch(resetFunction("reset"));

    // close dialog
    setDialogResetLayoutOpen(false);

    // set success alert message and open the success alert
    if (JSON.parse(localStorage.getItem("errorResetLayout"))) {
      // open fail alert
      dispatch(setIsFailAlertOpen(true));

      // set fail message
      dispatch(setAlertMessage(resetLayoutFailAlert));
    } else {
      // open success alert
      dispatch(setIsSuccessAlertOpen(true));

      // set success message
      dispatch(setAlertMessage(resetLayoutSuccessAlert));
    }
  };

  const handleImageChange = (e) => {
    // set isImageUploading state to true; this will cause the progress symbol to appear
    setIsImageUploading(true);

    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "cv-app-profile-images");

    axios
      .post("https://api.cloudinary.com/v1_1/dnuaxknhc/image/upload", formData)
      .then((responseCloudinary) => {
        // take url from cloudinary and store in db
        axiosInstance
          .put("/cvapp/employee-profile/", {
            imageURL: responseCloudinary.data.url,
          })
          .then((responseDjango) => {
            // open success alert
            dispatch(setIsSuccessAlertOpen(true));

            // set success alert metssage
            dispatch(setAlertMessage(imageSuccessAlert));

            dispatch(changeImagePath(responseDjango.data.imageURL));
            setIsImageUploading(false);
          })
          .catch((error) => {
            dispatch(setIsFailAlertOpen(true));
            dispatch(
              setAlertMessage(
                `${imageFailAlert} Error: ${error.response.status} ${error.response.statusText}`
              )
            );
            console.log("error when saving image url in db: ", error);
            setIsImageUploading(false);
          });
      })
      .catch((error) => {
        console.log("error in image upload clouidinary: ", error);
        setIsImageUploading(false);
      });
  };

  // fetch active users
  useEffect(() => {
    async function getUserStates() {
      // get active and inactive users
      await axiosInstance
        .get("/cvapp/active-users/", {
          params: {
            userType: "active",
          },
        })
        .then((response) => {
          console.log("we update active users");
          dispatch(initalizeActiveUsers(response.data));
          setActiveUsersLoaded(true);
        })
        .catch((error) => {
          console.log("error GET /cvapp/active-users/: ", error);
        });

      await axiosInstance
        .get("/cvapp/active-users/", {
          params: {
            userType: "inactive",
          },
        })
        .then((response) => {
          dispatch(initalizeInactiveUsers(response.data));
          setInactiveUsersLoaded(true);
        })
        .catch((error) => {
          console.log("error GET /cvapp/active-users/: ", error);
        });
    }

    getUserStates();
  }, []);

  // update active users
  useEffect(() => {
    if (activeUsersLoaded) {
      adminState.activeUsers.forEach((user) => {
        axiosInstance
          .put("/cvapp/active-users/", {
            id: user.id,
            active: true,
          })
          .then((response) => {
            // fetch again employee names of only active users (list of users in change user)
            axiosInstance.get("/cvapp/all-employee-names").then((response) => {
              const userNames = [];
              const userIds = [];

              dispatch(initalizeUsersComplete(response.data));

              response.data.map((user) => {
                userNames.push(user.name);
                userIds.push(user.id);
              });

              dispatch(initalizeUsersName(userNames));
              dispatch(initalizeUsersId(userIds));
            });
          })
          .catch((error) => {
            console.log("error PUT /cvapp/active-users/: ", error);
          });
      });
    }
  }, [activeUsersLoaded, adminState.activeUsers]);

  // update inactive users
  useEffect(() => {
    if (inactiveUsersLoaded) {
      adminState.inactiveUsers.forEach((user) => {
        axiosInstance
          .put("/cvapp/active-users/", {
            id: user.id,
            active: false,
          })
          .then((response) => {
            axiosInstance.get("/cvapp/all-employee-names").then((response) => {
              const userNames = [];
              const userIds = [];

              dispatch(initalizeUsersComplete(response.data));

              response.data.map((user) => {
                userNames.push(user.name);
                userIds.push(user.id);
              });

              dispatch(initalizeUsersName(userNames));
              dispatch(initalizeUsersId(userIds));
            });
          })
          .catch((error) => {
            console.log("error PUT /cvapp/active-users/: ", error);
          });
      });
    }
  }, [inactiveUsersLoaded, adminState.inactiveUsers]);

  const handleCheckedRight = () => {
    dispatch(
      initalizeInactiveUsers(adminState.inactiveUsers.concat(leftChecked))
    );
    dispatch(initalizeActiveUsers(not(adminState.activeUsers, leftChecked)));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    dispatch(initalizeActiveUsers(adminState.activeUsers.concat(rightChecked)));
    dispatch(
      initalizeInactiveUsers(not(adminState.inactiveUsers, rightChecked))
    );
    setChecked(not(checked, rightChecked));
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const customList = (items, emptyListText) => (
    <Paper
      sx={{ overflow: "auto", maxHeight: "500px" }}
      className="activate-user-paper">
      <List dense component="div" role="list">
        {items.map((value) => {
          // const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={
                    {
                      // "aria-labelledby": labelId,
                    }
                  }
                />
              </ListItemIcon>
              <ListItemText
                className="list-item-activate-user"
                // id={labelId}
                primary={`${value.name}`}
              />
            </ListItem>
          );
        })}
        {items?.length === 0 && (
          <div className="empty-user-text">{emptyListText}</div>
        )}
      </List>
    </Paper>
  );

  const handleBackdropOpen = () => dispatch(setBackDropOpenPhilicoLogo(true)); //setOpenBackdropPhilicoLogo(true);
  const handleBackdropClose = () => dispatch(setBackDropOpenPhilicoLogo(false)); //setOpenBackdropPhilicoLogo(false);

  const handleEmailSignatureCopyToClipboard = () => {
    // we can only trim phone value if not null
    var phoneNrWithoutWhiteSpace = profileIntoState.contactDetails.phone
      ? profileIntoState.contactDetails.phone.trim()
      : profileIntoState.contactDetails.phone;
    var emailSignatureHTML;

    // transform first name and last name such that only first letter is upper case
    var firstName =
      profileIntoState.contactDetails.firstName.charAt(0).toUpperCase() +
      profileIntoState.contactDetails.firstName.substring(1).toLowerCase();

    var lastName =
      profileIntoState.contactDetails.lastName.charAt(0).toUpperCase() +
      profileIntoState.contactDetails.lastName.substring(1).toLowerCase();

    if (profileIntoState.linkedinUrl) {
      // check if linkedinUrl has leading http:// or https:// (if no valid protocol is present)
      if (
        profileIntoState.linkedinUrl.indexOf("http://") === -1 &&
        profileIntoState.linkedinUrl.indexOf("https://") === -1
      ) {
        // find location of www.
        let wwwLocation = profileIntoState.linkedinUrl.indexOf("www");

        // check that wwwLocation is not -1 which means www is not present
        if (wwwLocation !== -1) {
          let newUrlValue =
            "https://" + profileIntoState.linkedinUrl.slice(wwwLocation);
          dispatch(changeLinkedinUrl(newUrlValue));
        } else {
          dispatch(setIsFailAlertOpen(true));

          // set fail message
          dispatch(setAlertMessage(htmlToClipboardWWWInvalidUrl));
          console.log("invalid url, www is missing");
          return;
        }
      }

      emailSignatureHTML = `<font
  style="font-family: Calibri, Arial, sans-serif; font-size: 10pt; color: #333">
  <table width="0" border="0" cellspacing="0" cellpadding="0">
    <tr>
      <td valign="top" style="padding-top: 3px; padding-right: 10px">
        <font style="font-weight: bolder; color: #004a8d; font-size: 11pt">
          ${firstName} ${lastName}
        </font>
        <br />
        <font style="color: #807e82; font-size: 10pt">
          <span>${internalInfoState.positionWithinPhilico}</span>
        </font>
      </td>
    </tr>
  </table>
  <table width="0" border="0" cellspacing="0" cellpadding="0">
    <tr>
      <td valign="top" style="padding-top: 25px; padding-left: 0px">
        <a href="http://www.philico.com/">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/public-assets-73db8.appspot.com/o/philico-logo-blue.png?alt=media&token=6de422e4-a361-43bb-af63-94dc6d5507f6"
            width="55"
            border="0" />
        </a>
      </td>
    </tr>
  </table>
  <table width="0" border="0" cellspacing="0" cellpadding="0">
    <tr>
      <td style="color: #828084; padding: 10px 0px 0px 0px; width: 12px">
        <img
          width="12"
          src="https://firebasestorage.googleapis.com/v0/b/public-assets-73db8.appspot.com/o/Email%20Signature%20Icons%2Ficon-phone-100x100.png?alt=media&token=4c31206f-2d6a-4611-addc-34dd1db49b59"
          alt="Phone Icon" />
      </td>
      <td style="color: #828084; padding: 10px 0px 0px 17px; width: 100px">
        <a
          href="tel:${phoneNrWithoutWhiteSpace}"
          style="
            font-family: Calibri,Arial,sans-serif;
            font-weight: light;
            text-decoration: unset !important;
            color: #828084;
            font-size: 12px;
            line-height: 14px;
          "
          text-decoration="none"
          >${profileIntoState.contactDetails.phone}</a
        >
      </td>
    </tr>
    <tr style="text-align: left">
      <td style="color: #828084; padding-bottom: 2px; width: 10px">
        <img
          width="13"
          src="https://firebasestorage.googleapis.com/v0/b/public-assets-73db8.appspot.com/o/Email%20Signature%20Icons%2Ficon-email-100x100.png?alt=media&token=bfe3e050-98e8-4298-bcee-d5c610f7476e"
          alt="Email Icon" />
      </td>
      <td style="color: #828084; padding: 0px 0px 3px 17px; width: 290px" ;>
        <a
          href="mailto:${profileIntoState.contactDetails.email}"
          style="
            font-family: Calibri, Arial, sans-serif;
            font-weight: light;
            text-decoration: unset !important;
            color: #828084;
            font-size: 12px;
            line-height: 14px;
          "
          >${profileIntoState.contactDetails.email}</a
        >
      </td>
    </tr>
    <tr style="text-align: left">
      <td style="color: #828084; width: 10px">
        <img
          width="13"
          src="https://firebasestorage.googleapis.com/v0/b/public-assets-73db8.appspot.com/o/Email%20Signature%20Icons%2Ficon-linkedin-100x100.png?alt=media&token=a1324a9e-8783-4539-9f68-318f85fed464"
          alt="LinkedIn Icon" />
      </td>
      <td style="color: #828084; padding: 0px 0px 3px 17px; width: 290px">
        <a
          href="${profileIntoState.linkedinUrl}"
          style="
            font-family: Calibri, Arial, sans-serif;
            font-weight: light;
            text-decoration: unset !important;
            color: #828084;
            font-size: 12px;
            line-height: 14px;
          "
          >LinkedIn profile</a
        >
      </td>
    </tr>
  </table>
</font>`;
    } else {
      emailSignatureHTML = `<font
  style="font-family: Calibri, Arial, sans-serif; font-size: 10pt; color: #333">
  <table width="0" border="0" cellspacing="0" cellpadding="0">
    <tr>
      <td valign="top" style="padding-top: 3px; padding-right: 10px">
        <font style="font-weight: bolder; color: #004a8d; font-size: 11pt">
          ${firstName} ${lastName}
        </font>
        <br />
        <font style="color: #807e82; font-size: 10pt">
          <span>${internalInfoState.positionWithinPhilico}</span>
        </font>
      </td>
    </tr>
  </table>
  <table width="0" border="0" cellspacing="0" cellpadding="0">
    <tr>
      <td valign="top" style="padding-top: 25px; padding-left: 0px">
        <a href="http://www.philico.com/">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/public-assets-73db8.appspot.com/o/philico-logo-blue.png?alt=media&token=6de422e4-a361-43bb-af63-94dc6d5507f6"
            width="55"
            border="0" />
        </a>
      </td>
    </tr>
  </table>
  <table width="0" border="0" cellspacing="0" cellpadding="0">
    <tr>
      <td style="color: #828084; padding: 10px 0px 0px 0px; width: 12px">
        <img
          width="12"
          src="https://firebasestorage.googleapis.com/v0/b/public-assets-73db8.appspot.com/o/Email%20Signature%20Icons%2Ficon-phone-100x100.png?alt=media&token=4c31206f-2d6a-4611-addc-34dd1db49b59"
          alt="Phone Icon" />
      </td>
      <td style="color: #828084; padding: 10px 0px 0px 17px; width: 100px">
        <a
          href="tel:${phoneNrWithoutWhiteSpace}"
          style="
            font-family: Calibri,Arial,sans-serif;
            font-weight: light;
            text-decoration: unset !important;
            color: #828084;
            font-size: 12px;
            line-height: 14px;
          "
          text-decoration="none"
          >${profileIntoState.contactDetails.phone}</a
        >
      </td>
    </tr>
    <tr style="text-align: left">
      <td style="color: #828084; padding-bottom: 2px; width: 10px">
        <img
          width="13"
          src="https://firebasestorage.googleapis.com/v0/b/public-assets-73db8.appspot.com/o/Email%20Signature%20Icons%2Ficon-email-100x100.png?alt=media&token=bfe3e050-98e8-4298-bcee-d5c610f7476e"
          alt="Email Icon" />
      </td>
      <td style="color: #828084; padding: 0px 0px 3px 17px; width: 290px" ;>
        <a
          href="mailto:${profileIntoState.contactDetails.email}"
          style="
            font-family: Calibri, Arial, sans-serif;
            font-weight: light;
            text-decoration: unset !important;
            color: #828084;
            font-size: 12px;
            line-height: 14px;
          "
          >${profileIntoState.contactDetails.email}</a
        >
      </td>
    </tr>
  </table>
</font>`;
    }

    copy(emailSignatureHTML, {
      debug: true,
      asHtml: true,
      onlyHtml: true,
    });

    setAnchorEl(null);

    dispatch(setIsSuccessAlertOpen(true));
    dispatch(setAlertMessage(htmlToClipboard));
  };

  return (
    <div className="header-flexbox-container" style={{ marginTop: "32px" }}>
      <HamburgerMenu />
      {/* <Backdrop
        open={internalInfoState.openBackdropPhilicoLogo}
        style={{ zIndex: "3" }}
      /> */}

      {/* <div className="flex-item-header-logo">
        <Box
          sx={{
            transform: "translateZ(0px)",
            flexGrow: 1,
            position: "relative",
            zIndex: "3",
            height: 320,
          }}>
          <SpeedDial
            className="hamburger-icon-speed-dial"
            ariaLabel="SpeedDial basic example"
            onClose={handleBackdropClose}
            onOpen={handleBackdropOpen}
            open={internalInfoState.openBackdropPhilicoLogo}
            icon={
              internalInfoState.openBackdropPhilicoLogo ? (
                <IconButton
                  className="flex-item-header"
                  sx={{ color: "philicoblue.main" }}
                  aria-label="add to shopping cart">
                  <img
                    src={crossIcon}
                    style={{ width: "64px", height: "64px", scale: "0.6" }}
                    alt="philico logo"
                  />
                </IconButton>
              ) : (
                <IconButton
                  className="flex-item-header"
                  sx={{ color: "philicoblue.main" }}
                  aria-label="add to shopping cart">
                  <img
                    src={PhilicoLogo}
                    style={{ width: "64px", height: "64px" }}
                    alt="philico logo"
                  />
                </IconButton>
              )
            }
            direction="down">
            {actions.map((action) => (
              <SpeedDialAction
                className="speed-dial-action-button-navigation"
                key={action.name}
                icon={action.name}
                onClick={(e) => {
                  navigate(action.url);
                  dispatch(setBackDropOpenPhilicoLogo(false));
                }}
              />
            ))}
          </SpeedDial>
        </Box>
      </div> */}
      <div className="flex-item-header-page-location">
        <div>
          {internalInfoState.openBackdropPhilicoLogo ? (
            <div> </div>
          ) : (
            <div>{adminState.pageLocationString}</div>
          )}
          {/* <div>Philico App {" > "} Personal Information</div> */}
          {/* <div>Personal Information </div> */}
          {/* <SvgIcon viewBox="0 0 17 17">
            <path
              d="M6.375 2.125C6.90036 2.125 7.41392 2.28079 7.85073 2.57266C8.28755 2.86453 8.62801 3.27938 8.82906 3.76475C9.0301 4.25011 9.0827 4.7842 8.98021 5.29946C8.87772 5.81472 8.62474 6.28802 8.25325 6.6595C7.88177 7.03099 7.40847 7.28397 6.89321 7.38646C6.37795 7.48895 5.84386 7.43635 5.3585 7.23531C4.87313 7.03426 4.45828 6.6938 4.16641 6.25698C3.87454 5.82017 3.71875 5.30661 3.71875 4.78125C3.71875 4.07677 3.9986 3.40114 4.49675 2.903C4.99489 2.40485 5.67052 2.125 6.375 2.125ZM6.375 1.0625C5.6395 1.0625 4.92052 1.2806 4.30897 1.68922C3.69743 2.09784 3.22079 2.67863 2.93932 3.35815C2.65786 4.03766 2.58422 4.78538 2.7277 5.50674C2.87119 6.22811 3.22537 6.89073 3.74545 7.4108C4.26552 7.93088 4.92814 8.28506 5.64951 8.42855C6.37087 8.57203 7.11859 8.49839 7.7981 8.21693C8.47762 7.93546 9.05841 7.45882 9.46703 6.84728C9.87565 6.23573 10.0938 5.51675 10.0938 4.78125C10.0938 3.79498 9.70195 2.8491 9.00455 2.1517C8.30715 1.4543 7.36127 1.0625 6.375 1.0625ZM11.6875 15.9375H10.625V13.2812C10.625 12.5768 10.3451 11.9011 9.847 11.403C9.34886 10.9049 8.67323 10.625 7.96875 10.625H4.78125C4.07677 10.625 3.40114 10.9049 2.903 11.403C2.40485 11.9011 2.125 12.5768 2.125 13.2812V15.9375H1.0625V13.2812C1.0625 12.295 1.4543 11.3491 2.1517 10.6517C2.8491 9.9543 3.79498 9.5625 4.78125 9.5625H7.96875C8.95502 9.5625 9.9009 9.9543 10.5983 10.6517C11.2957 11.3491 11.6875 12.295 11.6875 13.2812V15.9375ZM11.6875 2.125H17V3.1875H11.6875V2.125ZM11.6875 4.78125H17V5.84375H11.6875V4.78125ZM11.6875 7.4375H15.4062V8.5H11.6875V7.4375Z"
              fill="grey"
            />
          </SvgIcon>
          <div
            className="page-location-string">
            Personal Information
          </div> */}
        </div>
      </div>
      <div className="flex-item-header-avatar" id="avatar-bounding-div">
        <IconButton
          onMouseOver={handleMouseOver}
          id="avatar-button"
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}>
          <Avatar
            sx={{ bgcolor: "philicoblue.main", scale: "1.4" }}
            alt="PA"
            src={profileIntoState.image}
          />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          disableAutoFocus //disbaleAutoFocus is important otherwise page will jump to focused input element when avatar is unhovered
          id="account-menu"
          open={open}
          className="account-menu-avatar"
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
          {" "}
          <label htmlFor="contained-button-file">
            <input
              // accept="image/*"
              accept="image/png, image/jpeg"
              id="contained-button-file"
              type="file"
              onChange={handleImageChange}
              style={{ display: "None" }}
            />
            <MenuItem aria-label="upload picture" component="span">
              {isImageUploading ? (
                <CircularProgress
                  style={{ width: "24px", height: "24px", marginRight: "16px" }}
                />
              ) : (
                <LocalSeeIcon sx={{ mr: 2 }} />
              )}
              Change CV Picture
            </MenuItem>
          </label>
          <MenuItem
            component="span"
            onClick={handleEmailSignatureCopyToClipboard}>
            {/* <ManageAccountsIcon sx={{ mr: 2 }} /> */}
            <img src={EmailSignatureIcon} style={{ marginRight: "16px" }} />
            Create Email Signature
          </MenuItem>
          {adminState.cvAppUserRole.includes("Admin") && (
            <MenuItem
              component="span"
              onClick={(e) => {
                setDialogChangeUserOpen(true);
                // e.currentTarget.focus();
                // console.log("e.current.focus: ", e.currentTarget);
              }}>
              <ManageAccountsIcon sx={{ mr: 2 }} />
              Change User
            </MenuItem>
          )}
          {adminState.cvAppUserRole.includes("SuperUser") && (
            <MenuItem
              component="span"
              onClick={(e) => setDialogManageUserRolesOpen(true)}>
              <SupervisorAccountIcon sx={{ mr: 2 }} />
              Manage User Roles
            </MenuItem>
          )}
          {adminState.cvAppUserRole.includes("SuperUser") && (
            <MenuItem
              component="span"
              onClick={(e) => setDialogActiveUsersOpen(true)}>
              <PeopleAltIcon sx={{ mr: 2 }} />
              Activate/Deactive Users
            </MenuItem>
          )}
          <MenuItem
            component="span"
            onClick={(e) => setDialogResetLayoutOpen(true)}>
            <RestartAltIcon sx={{ mr: 2 }} />
            Reset cards layout
          </MenuItem>
          <MenuItem
            component="span"
            onClick={(e) => navigate("/release-notes")}>
            <NewReleasesIcon sx={{ mr: 2 }} />
            Release notes
          </MenuItem>
          <MenuItem component="span" onClick={(e) => setDialogLogoutOpen(true)}>
            <LogoutIcon sx={{ mr: 2 }} />
            Logout
          </MenuItem>
        </Menu>
      </div>
      <Dialog
        open={dialogResetLayoutOpen}
        onClose={handleDialogResetLayoutClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <div className="dialog-reset-layout-wrapper">
          <div className="dialog-reset-layouts-title">Reset Card layouts?</div>
          <div className="dialog-reset-layouts-subtitle">
            Resetting the cards layout will restore the default layout. Do you
            want to reset the cards layout?
          </div>
          <div style={{ marginTop: "12px", float: "right" }}>
            <Button
              // type="submit"
              onClick={handleCardsLayoutReset}
              variant="contained">
              RESET
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={dialogManageUserRolesOpen}
        onClose={handleDialogManageUserRolesClose}
        className="manage-user-role-papers"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <div className="manage-user-roles-wrapper">
          <div className="dialog-reset-layouts-title">Manage User Roles</div>
          <div className="manage-user-roles-grid-wrapper">
            <Tooltip
              title="Employee user role has the basic rights, to create and download a CV for his own data"
              arrow
              placement="top">
              <div
                style={{ gridColumn: "2", justifySelf: "center" }}
                className="user-role-title">
                Employee
              </div>
            </Tooltip>
            <Tooltip
              title="Admin user role has the rights, to view and edit other user's data. Admin users also have the ability to download CV for all users."
              arrow
              placement="top">
              <div
                style={{ gridColumn: "3", justifySelf: "center" }}
                className="user-role-title">
                Admin
              </div>
            </Tooltip>
            <Tooltip
              title="Manager user role has the rights, to view and edit other user's data, to download CVs for all users and can be selected as a Coach for other users."
              arrow
              placement="top">
              <div
                style={{ gridColumn: "4", justifySelf: "center" }}
                className="user-role-title">
                Manager
              </div>
            </Tooltip>
            <Tooltip
              title="Timesheet approver user role has the rights to approve other user's Timesheets. This user will appear as an approver column in the timesheet admin section."
              arrow
              placement="top">
              <div
                style={{ gridColumn: "5", justifySelf: "center" }}
                className="user-role-title">
                Timesheet Approver
              </div>
            </Tooltip>
            <Tooltip
              title="CvApprover user role has the rights to approve other user's CV's. In addition users with user role CvApprover get notified via Email if a user updates its CV data."
              arrow
              placement="top">
              <div
                style={{ gridColumn: "6", justifySelf: "center" }}
                className="user-role-title">
                cvApprover
              </div>
            </Tooltip>
            <Tooltip
              title="SuperAdmin user role has the rights, to view and edit other user's data. In addition a SuperAdmin can also manage user roles of other users and activate or deactive users."
              arrow
              placement="top">
              <div
                style={{ gridColumn: "7", justifySelf: "center" }}
                className="user-role-title">
                SuperAdmin
              </div>
            </Tooltip>
            {adminState.userRoles.map((user) => {
              return (
                <>
                  <div
                    style={{
                      gridColumn: "1",
                      alignSelf: "center",
                      fontSize: "14px",
                    }}
                    key={user.name}>
                    {user.name}
                  </div>
                  <div style={{ gridColumn: "2", justifySelf: "center" }}>
                    <Checkbox defaultChecked={true} disabled />
                  </div>
                  <div style={{ gridColumn: "3", justifySelf: "center" }}>
                    <Checkbox
                      checked={user.userRole.includes("Admin")}
                      onChange={(e) =>
                        axiosInstance
                          .put("/cvapp/user-roles/", {
                            id: user.id,
                            addRole: e.target.checked,
                            role: "Admin",
                            ...user,
                          })
                          .then((response) => {
                            //if updated user is logged in user we will make immediate change
                            if (
                              response.data.id ===
                              Number(localStorage.getItem("loggedInUser"))
                            ) {
                              dispatch(changeUserRole(response.data.userRole));
                            }
                            dispatch(
                              handleUserRoleChange({
                                newObj: response.data,
                                userRoles: adminState.userRoles,
                              })
                            );
                          })
                          .catch((error) => {
                            console.log(
                              "error PUT /cvapp/user-roles/: ",
                              error
                            );
                          })
                      }
                    />
                  </div>
                  <div style={{ gridColumn: "4", justifySelf: "center" }}>
                    <Checkbox
                      checked={user.userRole.includes("Manager")}
                      onChange={(e) =>
                        axiosInstance
                          .put("/cvapp/user-roles/", {
                            id: user.id,
                            addRole: e.target.checked,
                            role: "Manager",
                            ...user,
                          })
                          .then((response) => {
                            //if updated user is logged in user we will make immediate change

                            if (
                              response.data.id ===
                              Number(localStorage.getItem("loggedInUser"))
                            ) {
                              dispatch(changeUserRole(response.data.userRole));
                            }
                            dispatch(
                              handleUserRoleChange({
                                newObj: response.data,
                                userRoles: adminState.userRoles,
                              })
                            );
                          })
                          .catch((error) => {
                            console.log(
                              "error PUT /cvapp/user-roles/: ",
                              error
                            );
                          })
                      }
                    />
                  </div>
                  <div style={{ gridColumn: "5", justifySelf: "center" }}>
                    <Checkbox
                      checked={user.userRole.includes("timesheetApprover")}
                      onChange={(e) =>
                        axiosInstance
                          .put("/cvapp/user-roles/", {
                            id: user.id,
                            addRole: e.target.checked,
                            role: "timesheetApprover",
                            ...user,
                          })
                          .then((response) => {
                            if (
                              response.data.id ===
                              Number(localStorage.getItem("loggedInUser"))
                            ) {
                              dispatch(changeUserRole(response.data.userRole));
                            }
                            dispatch(
                              handleUserRoleChange({
                                newObj: response.data,
                                userRoles: adminState.userRoles,
                              })
                            );
                          })
                          .catch((error) => {
                            console.log(
                              "error PUT /cvapp/user-roles/: ",
                              error
                            );
                          })
                      }
                    />
                  </div>
                  <div style={{ gridColumn: "6", justifySelf: "center" }}>
                    <Checkbox
                      checked={user.userRole.includes("cvApprover")}
                      onChange={(e) =>
                        axiosInstance
                          .put("/cvapp/user-roles/", {
                            id: user.id,
                            addRole: e.target.checked,
                            role: "cvApprover",
                            ...user,
                          })
                          .then((response) => {
                            if (
                              response.data.id ===
                              Number(localStorage.getItem("loggedInUser"))
                            ) {
                              dispatch(changeUserRole(response.data.userRole));
                            }
                            dispatch(
                              handleUserRoleChange({
                                newObj: response.data,
                                userRoles: adminState.userRoles,
                              })
                            );
                          })
                          .catch((error) => {
                            console.log(
                              "error PUT /cvapp/user-roles/: ",
                              error
                            );
                          })
                      }
                    />
                  </div>
                  <div style={{ gridColumn: "7", justifySelf: "center" }}>
                    <Checkbox
                      checked={user.userRole.includes("SuperUser")}
                      onChange={(e) =>
                        axiosInstance
                          .put("/cvapp/user-roles/", {
                            id: user.id,
                            addRole: e.target.checked,
                            role: "SuperUser",
                            ...user,
                          })
                          .then((response) => {
                            if (
                              response.data.id ===
                              Number(localStorage.getItem("loggedInUser"))
                            ) {
                              dispatch(changeUserRole(response.data.userRole));
                            }
                            dispatch(
                              handleUserRoleChange({
                                newObj: response.data,
                                userRoles: adminState.userRoles,
                              })
                            );
                          })
                          .catch((error) => {
                            console.log(
                              "error PUT /cvapp/user-roles/: ",
                              error
                            );
                          })
                      }
                    />
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </Dialog>
      <Dialog
        open={dialogActivateUserOpen}
        onClose={handleDialogActivateUserClose}
        className="activate-user-role-papers"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <div className="manage-user-roles-wrapper">
          <div className="dialog-reset-layouts-title">
            Activate/Deactivate Users
          </div>
          <div className="activate-users-grid-wrapper">
            <div style={{ gridColumn: "1" }}>
              <h2 className="title-active-user">Active Users</h2>
              {customList(adminState.activeUsers, "No active users")}
            </div>
            <div
              style={{
                gridColumn: "2",
                display: "flex",
                flexDirection: "column",
                justifySelf: "center",
                alignSelf: "center",
              }}>
              <Button
                sx={{ my: 0.5 }}
                // style={{}}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                style={{ color: "white", width: "64px", marginTop: "22px" }}>
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                style={{ color: "white", width: "64px" }}>
                &lt;
              </Button>
            </div>

            <div style={{ gridColumn: "3" }}>
              {" "}
              <h2 className="title-active-user">Inactive Users</h2>
              {customList(adminState.inactiveUsers, "No inactive users")}
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={dialogChangeUserOpen}
        onClose={handleDialogChangeUserClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <div className="dialog-reset-layout-wrapper">
          <div className="dialog-reset-layouts-title">Change User?</div>
          <div className="dialog-reset-layouts-subtitle">
            Select a user of whom you would like to see and edit its data?
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div style={{ width: "50%", margin: "32px auto 0px auto" }}>
              <SelectCustomized
                label="Users"
                fullWidth={true}
                name="user"
                select_options={adminState.usersName}
                values={adminState.usersId}
                onChange={formik.handleChange}
                error={formik.errors.user && formik.touched.user ? true : false}
                helperText={
                  formik.errors.user && formik.touched.user
                    ? formik.errors.user
                    : " "
                }
              />
            </div>
            <div
              style={{ marginTop: "8px", marginBottom: "8px", float: "right" }}>
              <Button
                type="submit"
                // onClick={handleChangeUser}
                variant="contained">
                CHANGE USER
              </Button>
            </div>
          </form>
        </div>
      </Dialog>
      <LogoutDialog
        open={dialogLogoutOpen}
        onClose={handleLogoutDialogClose}
        handleLogout={handleLogout}
      />
      <Backdrop
        style={{ zIndex: "3" }}
        sx={{ backgroundColor: "rgba(156, 156, 156, 0.5)" }}
        open={backdropOpen}>
        <CircularProgress sx={{ zIndex: "2001" }} />
      </Backdrop>
    </div>
  );
}

export default Header;
