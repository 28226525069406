import { arrayMove } from "@dnd-kit/sortable";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  something: "",
  activityType: "",
  activity: "",
  duration: null,
  site: "",
  activities: [],
  activityList: [],
  comment: "",
  groupingState: [],
  selectedDates: null,
  filterSelectedDates: [null, null],
  lastChipSet: 0,
  durationEditValue: "",
  durationEditValidationError: false,
  dateEditValue: "",
  dateEditValidationError: false,
  initialGrouping: [],
  densityState: "compact",
  isWeekendEnabled: false,
  employeeList: [],
};

export const timeSheetSlice = createSlice({
  name: "timesheet",
  initialState,
  reducers: {
    initalizeSomething: (state, action) => {
      console.log("testing ");
      state.something = action.payload;
    },
    setDensityState: (state, action) => {
      state.densityState = action.payload;
    },
    setIsWeekendEnabled: (state, action) => {
      state.isWeekendEnabled = action.payload;
    },
    setActivityType: (state, action) => {
      state.activityType = action.payload;
    },
    setActivity: (state, action) => {
      state.activity = action.payload;
    },
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
    setSite: (state, action) => {
      state.site = action.payload;
    },
    setActivities: (state, action) => {
      state.activities = action.payload;
    },
    setActivityList: (state, action) => {
      state.activityList = state.activities
        // .find((group) => group.activity_type === activityType)
        .find((group) => group.activity_type === state.activityType)
        .activities.map((activity) => activity.activity_name);
    },
    setComment: (state, action) => {
      state.comment = action.payload;
    },
    setDailyGroupingState: (state, action) => {
      let groupState = ["date"];
      state.groupingState = groupState;
    },
    remvGrouping: (state, action) => {
      state.groupingState = [];
    },
    setLastChipSet: (state, action) => {
      state.lastChipSet = action.payload;
    },
    setSelectedDates: (state, action) => {
      state.selectedDates = action.payload;
    },
    setFilterSelectedDates: (state, action) => {
      state.filterSelectedDates = action.payload;
    },
    setDurationEditValue: (state, action) => {
      state.durationEditValue = action.payload;
    },
    setDurationEditValidationError: (state, action) => {
      state.durationEditValidationError = action.payload;
    },
    setDateEditValue: (state, action) => {
      state.dateEditValue = action.payload;
    },
    setDateValidationError: (state, action) => {
      state.dateEditValidationError = action.payload;
    },
    setInitialGrouping: (state, action) => {
      state.initialGrouping = action.payload;
    },
    setEmployeeList: (state, action) => {
      state.employeeList = action.payload;
    },
    handleUpdateDragDifferentContainer: (state, action) => {
      //copy the state
      var newEmployeeList = [...state.employeeList];

      const [removedItem] = newEmployeeList[
        action.payload.activeContainerIndex
      ].coachees.splice(action.payload.activeItemIndex, 1);

      newEmployeeList[action.payload.overContainerIndex].coachees.splice(
        action.payload.overItemIndex,
        0,
        removedItem
      );

      state.employeeList = newEmployeeList;
    },
    handleUpdateDragSameContainer: (state, action) => {
      // copy the state
      var newEmployeeList = [...state.employeeList];

      newEmployeeList[action.payload.activeContainerIndex].coachees = arrayMove(
        newEmployeeList[action.payload.activeContainerIndex].coachees,
        action.payload.activeItemIndex,
        action.payload.overItemIndex
      );
    },
    handleUpdateSameContainerDrag: (state, action) => {
      var newEmployeeList = state.employeeList;

      newEmployeeList[action.payload.activeContainerIndex].coachees = arrayMove(
        newEmployeeList[action.payload.activeContainerIndex].coachees,
        action.payload.activeItemIndex,
        action.payload.overItemIndex
      );

      state.employeeList = newEmployeeList;
    },
    handleDragEndTimesheetApprovers: (state, action) => {
      //copy the state
      var employeeListTemporary = state.employeeList;

      // get object of new area
      var overObject = employeeListTemporary.find((obj) => {
        return obj.reviewer === action.payload.over;
      });

      // old over object
      var oldOverObject = employeeListTemporary.find((obj) => {
        return obj.coachees.includes(action.payload.active);
      });

      // remove coachee from old object
      const index = oldOverObject.coachees.indexOf(action.payload.active);
      if (index > -1) {
        // only splice array when item is found
        oldOverObject.coachees.splice(index, 1); // 2nd parameter means remove one item only
      }

      // append new coachee to array
      overObject.coachees.push(action.payload.active);

      var intermediaryArray = employeeListTemporary.map((item) => {
        return item.reviewer === overObject.reviewer ? overObject : item;
      });

      var finalArray = intermediaryArray.map((item) => {
        return item.reviewer === oldOverObject.reviewer ? oldOverObject : item;
      });

      state.employeeList = finalArray;
    },
  },
});

export const {
  initalizeSomething,
  setActivityType,
  setActivity,
  setDuration,
  setSite,
  setActivities,
  setActivityList,
  setDateEditValue,
  setComment,
  setDensityState,
  setDailyGroupingState,
  remvGrouping,
  setLastChipSet,
  setSelectedDates,
  setFilterSelectedDates,
  setDurationEditValue,
  setDurationEditValidationError,
  setDateValidationError,
  setInitialGrouping,
  setIsWeekendEnabled,
  setEmployeeList,
  handleDragEndTimesheetApprovers,
  handleUpdateSameContainerDrag,
  handleUpdateDragDifferentContainer,
  handleUpdateDragSameContainer,
} = timeSheetSlice.actions;

export default timeSheetSlice.reducer;
