import React, { useEffect, useState } from "react";
import actions_group_icon from "../../../assets/ActionsGroupSymbol.svg";
import cv_guidelines_icon from "../../../assets/cv_guidelines_icon.svg";
import cv_data_update_icon from "../../../assets/cv_data_update_icon.svg";
import approve_cv_data_icon from "../../../assets/approve_cv_data_icon.svg";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import { useSelector, useDispatch } from "react-redux";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import ActionGroupElement from "./ActionGroupElement";
import axiosInstance from "../../../axios/axiosConfig";
import {
  initalizeDataWorkflowObject,
  setIsDataApproving,
  setIsDataApprovingGeneral,
  setIsDataUpdating,
} from "../../../features/cvDataWorkflowSlice";
import CircularProgress from "@mui/material/CircularProgress";
import {
  setAlertMessage,
  setIsFailAlertOpen,
  setIsSuccessAlertOpen,
} from "../../../features/alertMessageSlice";
import {
  cvDataApproveFail,
  cvDataApprovePartialSuccess,
  cvDataApproveSuccess,
  cvDataUpdatedFail,
  cvDataUpdatedSuccess,
} from "../../../components/AlertMessages";
import Parser from "html-react-parser";

function CvActionsGroup() {
  const [zIndexGuidelines, setZIndexGuidelines] = useState(5);
  const [anchorEl, setAnchorEl] = React.useState(null);
  //   const [open, setOpen] = React.useState(false);
  const internalInfoState = useSelector((state) => state.internalinfo);
  const adminState = useSelector((state) => state.admin);
  const cvDataWorkflowState = useSelector((state) => state.cvdataworkflow);

  const dispatch = useDispatch();
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (internalInfoState.openBackdropPhilicoLogo) {
      setZIndexGuidelines(1);
    } else {
      const timeout = setTimeout(() => {
        setZIndexGuidelines(5);
      }, 300);

      // clearTimeout(timeout);
    }
  }, [internalInfoState.openBackdropPhilicoLogo]);

  const handleMenuClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCvGuidelinesClick = () => {
    window.open("/#/cv-guidelines", "_blank");
  };

  const handleCvDataApproveClick = (type) => {
    //get current date
    var currentDate = moment().format("DD.MM.YYYY");
    console.log("type when clicking approve: ", type);
    var requestBody;
    if (type === "avaloq") {
      dispatch(setIsDataApproving(true));
      requestBody = { lastTimeApproved: currentDate };
    } else if (type === "general") {
      dispatch(setIsDataApprovingGeneral(true));
      requestBody = { lastTimeApprovedGeneral: currentDate };
    }

    axiosInstance
      .put("/cvapp/cv-data-workflow/", requestBody)
      .then((response) => {
        //update state
        dispatch(initalizeDataWorkflowObject(response.data));

        dispatch(setIsDataApproving(false));
        dispatch(setIsDataApprovingGeneral(false));

        //   display success alert
        dispatch(setIsSuccessAlertOpen(true));
        if (response.status === 207) {
          dispatch(setAlertMessage(cvDataApprovePartialSuccess));
        } else {
          dispatch(setAlertMessage(cvDataApproveSuccess));
        }
      })
      .catch((error) => {
        console.log("error updated lastTimeApproved");
        dispatch(setIsDataApproving(false));
        dispatch(setIsDataApprovingGeneral(false));
        dispatch(setIsFailAlertOpen(true));
        dispatch(setAlertMessage(cvDataApproveFail));
      });
  };

  const handleCvDataUpdateClick = () => {
    dispatch(setIsDataUpdating(true));

    //get current date
    var currentDate = moment().format("DD.MM.YYYY");

    // console.log("current date: ", currentDate);
    // console.log("currentDateNew: ", currentDateNew);

    axiosInstance
      .put("/cvapp/cv-data-workflow/", {
        lastTimeUpdated: currentDate,
      })
      .then((response) => {
        //update state
        dispatch(initalizeDataWorkflowObject(response.data));

        //   remove circular progress icon
        dispatch(setIsDataUpdating(false));

        //   display success alert
        dispatch(setIsSuccessAlertOpen(true));
        dispatch(setAlertMessage(cvDataUpdatedSuccess));
      })
      .catch((error) => {
        console.log("error updated lastTimeUpdated");
        dispatch(setIsDataUpdating(false));
        dispatch(setIsFailAlertOpen(true));
        dispatch(setAlertMessage(cvDataUpdatedFail));
      });
  };

  return (
    <div>
      <IconButton
        style={{
          position: "relative",
          padding: "16px",
          marginLeft: "-2px",
          zIndex: zIndexGuidelines, //need to set zindex of guidelines button dynamically; otherwise dropdown menu of philico logo overlays and prevents clicking the button
        }}
        onClick={handleMenuClick}>
        <img src={actions_group_icon} />
      </IconButton>{" "}
      <Popover
        style={{ marginLeft: "6px" }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 16,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: -1,
            },
          },
        }}
        // id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <div className="cv-actions-dots-group-flexbox-wrapper">
          <ActionGroupElement
            icon={cv_guidelines_icon}
            title="CV Guidelines"
            handleClick={handleCvGuidelinesClick}
          />
          {/* <ActionGroupElement
            icon={cv_data_update_icon}
            title="Confirm CV Data update"
            handleClick={handleCvDataUpdateClick}
          /> */}

          {adminState.cvAppUserRole.includes("cvApprover") ? (
            <>
              {localStorage.getItem("loggedInUser") ===
                localStorage.getItem("activeUser") && (
                <ActionGroupElement
                  icon={cv_data_update_icon}
                  title="Confirm CV Data update"
                  handleClick={handleCvDataUpdateClick}
                  showProgressIcon={cvDataWorkflowState.isDataUpdating}
                />
              )}
              <ActionGroupElement
                icon={approve_cv_data_icon}
                title="Approve CV Data (Avaloq)"
                type="avaloq"
                handleClick={handleCvDataApproveClick}
                showProgressIcon={cvDataWorkflowState.isDataApproving}
                //   showProgressIcon={true}
              />
              <ActionGroupElement
                icon={approve_cv_data_icon}
                title="Approve CV Data (General)"
                type="general"
                handleClick={handleCvDataApproveClick}
                showProgressIcon={cvDataWorkflowState.isDataApprovingGeneral}
                //   showProgressIcon={true}
              />
            </>
          ) : (
            <ActionGroupElement
              icon={cv_data_update_icon}
              title="Confirm CV Data update"
              handleClick={handleCvDataUpdateClick}
              showProgressIcon={cvDataWorkflowState.isDataUpdating}
            />
          )}
        </div>
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
      </Popover>
    </div>
  );
}

export default CvActionsGroup;
