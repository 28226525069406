import * as Yup from "yup";

export function validationSchemaHelper(schemaType) {

  switch (schemaType) {
    case "MonthYear":
      return Yup.object({
        month: Yup.string().required("Month is required!"),
        year: Yup.string().required("Year is required!"),
      });

    case "Year":
      return Yup.object({
        year: Yup.string().required("Year is required!"),
      });

    case "MonthYearLocation":
      return Yup.object({
        month: Yup.string().required("Month is required!"),
        year: Yup.string().required("Year is required!"),
        location: Yup.array()
          .of(Yup.string().required("Array elements must be strings")) // Optional: Validate elements inside the array
          .min(1, "Location must contain at least one item!") // Ensure the array is non-empty
          .required("Location is required"), // Ensure the array itself is defined
      });

    default:
      throw new Error(`Invalid schemaType: ${schemaType}`);
  }
}
