import React, { useRef, useEffect, useState } from "react";
import { DndContext } from "@dnd-kit/core";
import "./Testing.css";

import Header from "../../components/Header";
import EmployeeApprovalCard from "../TimeMate/components/EmployeeApprovalCard/EmployeeApprovalCard";

const employeesListDB = [
  {
    is_active_user: false,
    reviewer: "Alex Müller",
    coachees: [25, 16, 15, 19],
  },
  {
    is_active_user: false,
    reviewer: "Fabien Roth",
    coachees: [18, 36, 23, 26, 10, 17],
  },
];

function Testing() {
  const [isDropped, setIsDropped] = useState(false);
  const [employeesList, setEmployeesList] = useState(employeesListDB);

  const container = useRef(null);

  function handleDragEnd(event) {
    if (event.over && event.over.id === "droppable") {
      setIsDropped(true);
    }
  }

  // useEffect(() => {
  //   // const container = document.querySelector('.container')!
  //   console.log("this is container: ", container);
  //   const swapy = createSwapy(container.current, {
  //     swapMode: "hover",
  //   });
  //   swapy.onSwap(({ data }) => {
  //     console.log("swap", data);
  //     // localStorage.setItem("slotItem", JSON.stringify(data.object));
  //   });

  //   swapy.onSwapEnd(({ data, hasChanged }) => {
  //     console.log(hasChanged);
  //     console.log("end", data);
  //   });

  //   swapy.onSwapStart(() => {
  //     console.log("start");
  //   });

  //   return () => {
  //     swapy.destroy();
  //   };
  // }, [container.current]);

  return (
    <>
      <div style={{ maxWidth: "1260px", margin: "auto" }}>
        <Header />
        <div className="timemate-admin-column-container">
          {employeesList.map((reviewer) => {
            return (
              <div className="reviewer-column">
                <div className="timemate-admin-column-title">
                  {reviewer.is_active_user ? "My Reviews" : reviewer.reviewer}
                </div>
                {reviewer.coachees.map((coachee) => {
                  return (
                    <div className="timemate-admin-left-column-content">
                      <EmployeeApprovalCard
                        key={coachee}
                        employeeId={coachee}
                        monthYear="September 2024"
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {/* <div class="container-outer-dragging" ref={container}>
          {employeesList.map((reviewer) => {
            return (
              <div>
                <div>{reviewer.reviewer} </div>

                {reviewer.coachees.map((coachee) => {
                  return (
                    <div data-swapy-slot={`${reviewer.reviewer}-${coachee}`}>
                      <div
                        data-swapy-item={coachee}
                        className={coachee ? "draggable-item" : "hidden-item"}>
                        {coachee}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div> */}

        {/* <Drop> */}
      </div>
    </>
  );
}

export default Testing;
